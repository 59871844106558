<template>
  <div>
    <el-drawer
      title="编辑广告组"
      size="1200px"
      append-to-body
      :visible="editAdSetShow"
      @close="close"
      @opened="openInit"
      v-loading="adsInfoLoading"
    >
      <div class="h-full p-5 pb-20">
        <el-form
          ref="form"
          label-width="120px"
          class="h-full overflow-y-auto"
          :model="adsMsg"
        >
          <el-form-item label="广告组名称">
            <el-input
              v-model="adsMsg.name"
              placeholder="广告组名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="广告组状态">
            <el-switch
              v-model="adsMsg.status"
              active-text="开启"
              inactive-text="暂停"
              active-value="ENABLED"
              inactive-value="PAUSED"
            >
            </el-switch>
          </el-form-item>
          <p class="font-semibold text-[#666] text-[16px]">定向</p>
          <el-form-item
            label="地区"
            prop="groupCriterion.target.locations"
            :rules="[{ required: true, message: '受众地区不能为空' }]"
          >
            <div class="flex">
              <el-select
                class="flex-1"
                multiple
                filterable
                default-first-option
                value-key="country_code"
                v-model="adsMsg.groupCriterion.target.locations"
                @change="(v) => onChangeLocations(v, 'locations')"
              >
                <el-option
                  v-for="item in enumConstants.countries"
                  :key="item.country_code"
                  :label="item.name_zhcn"
                  :value="item"
                >
                </el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item label="语言">
            <el-select
              multiple
              class="w-full"
              filterable
              default-first-option
              v-model="adsMsg.groupCriterion.target.languages"
            >
              <el-option
                v-for="languages in enumConstants.languages"
                :label="languages.nameZhcn"
                :value="languages.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <p class="font-semibold text-[#666] text-[16px]">受众</p>
          <el-form-item label="受众群体">
            <AudienceGroup
              ref="audienceGroup"
              v-model="adsMsg.groupCriterion.audience.resourceName"
              :accountId="createAdAccount.id"
              :currentAccount="createAdAccount"
              @selectAudienceGroup="selectAudienceGroup"
            />
          </el-form-item>
          <el-form-item label="性别">
            <AudienceSex
              v-model="adsMsg.groupCriterion.audience.genders"
              :disabled="true"
            />
          </el-form-item>
          <el-form-item label="年龄">
            <AudienceAge
              v-model="adsMsg.groupCriterion.audience.ageRange"
              :disabled="true"
            />
          </el-form-item>
          <el-form-item label="自定义细分受众">
            <CustomAudience
              ref="customAudience"
              :currentAccount="createAdAccount"
              v-model="adsMsg.groupCriterion.audience.customAudiences"
              :disabled="true"
            />
          </el-form-item>
          <el-form-item label="兴趣和详细受众">
            <InterestAudience
              ref="interestAudience"
              v-model="adsMsg.groupCriterion.audience.userInterests"
              :list="interestList"
              :interestLabelSaveAPI="interestLabelSaveAPI"
              :countryCodes="adsMsg.groupCriterion.target.locations"
              :accountId="createAdAccount.id"
              :defaultExpandAll="false"
              :disabled="true"
            ></InterestAudience>
          </el-form-item>
          <el-form-item label="优化型定位">
            <OptimizePosition
              :value="adsMsg"
              @input="(v) => setAudiencePosition(v)"
            />
          </el-form-item>
        </el-form>
      </div>
      <div class="absolute right-3 bottom-5">
        <el-button @click="close">取 消</el-button>
        <el-button
          type="primary"
          @click="sure"
          >确 定</el-button
        >
      </div>
    </el-drawer>
  </div>
</template>
<script>
import AudienceAge from '@/views/google/create/components/audience/AudienceAge/index.vue';
import AudienceGroup from '@/views/google/create/components/audience/AudienceGroup/index.vue';
import InterestAudience from '@/views/google/create/components/audience/interestAudiences/index.vue';
import CreateSegmentAudience from '@/views/google/create/components/audience/createSegmentAudience/index.vue';
import OptimizePosition from '@/views/google/create/components/audience/OptimizePosition/index.vue';
import CustomAudience from '@/views/google/create/components/audience/CustomAudience/index.vue';
import AudienceSex from '@/views/google/create/components/audience/AudienceSex/index.vue';
import { callToActionList, sexList, interestList } from '@/views/google/create/index';
import { adDetail, adConstMapGG, saveCommonInterests, updateAd } from '@/api/google/create';
import { mapState } from 'vuex';
export default {
  components: {
    AudienceGroup,
    AudienceAge,
    InterestAudience,
    CreateSegmentAudience,
    OptimizePosition,
    CustomAudience,
    AudienceSex,
  },
  props: {
    editAdSetShow: {
      type: Boolean,
      default: false,
    },
    currentRow: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapState('google', ['createAdAccount']),
  },
  data() {
    return {
      sexList,
      interestLabelSaveAPI: saveCommonInterests,
      interestList,
      adsMsg: {
        groupCriterion: {
          audience: {},
          target: {},
        },
      },
      enumConstants: {
        callToActionList,
      },
      adsInfoLoading: false,
      customAudienceListLoading: false,
      customAudienceList: [],
      adCommon: {},
    };
  },
  methods: {
    // 初始化弹窗
    openInit() {
      this.getEnumConstants();
      this.getAdsInfo();
      this.$nextTick(() => {
        console.log(this.$refs.audienceGroup);
        this.$refs.audienceGroup.getAudienceGroupList();
        this.$refs.customAudience.getCustomAudienceList();
      });
    },
    getEnumConstants() {
      adConstMapGG().then((res) => {
        if (res.code == 0) {
          this.enumConstants.countries = res.data.defaultCountries;
          this.enumConstants.languages = res.data.defaultLanguages;
        }
      });
    },
    // 获取广告信息
    getAdsInfo() {
      let params = {
        level: 'adset',
        accountId: this.createAdAccount.id,
        accountName: this.createAdAccount.name,
        campaignId: this.currentRow.campaign_id,
        adGroupId: this.currentRow.adset_id,
      };
      this.adsInfoLoading = true;
      adDetail(params)
        .then((res) => {
          if (res.code == 0) {
            this.adsMsg = res.data.adGroup;
            this.adCommon = { ...res.data, fromAdGroupAudience: this.adsMsg.groupCriterion.audience.resourceName };
            this.adsMsg.groupCriterion.target.locations = this.adsMsg.groupCriterion.target.locations.map((v) => {
              return {
                ...v,
                criteria_id: v.id,
                name_zhcn: v.name,
                country_code: v.code,
              };
            });
            this.adsMsg.groupCriterion.audience.userInterests = this.adsMsg.groupCriterion.audience.userInterests
              ? this.adsMsg.groupCriterion.audience.userInterests
              : [];
            this.adsMsg.groupCriterion.audience.customAudiences =
              this.adsMsg.groupCriterion.audience.customAudiences || [];
          }
        })
        .finally(() => {
          this.adsInfoLoading = false;
        });
    },
    //关闭弹窗
    close() {
      this.$emit('update:editAdSetShow', false);
    },
    //点击确定
    sure() {
      console.log(this.adsMsg);
      console.log(this.enumConstants.countries);
      this.$refs.form.validate((validate) => {
        console.log(validate);
        if (!validate) {
          return false;
        }
        let params = JSON.parse(JSON.stringify(this.adsMsg));
        params.groupCriterion.target.locations = params.groupCriterion.target.locations.map((v) => {
          return {
            id: v.criteria_id,
            name: v.name_zhcn,
            code: v.country_code,
          };
        });
        this.$showLoading();
        updateAd({ ...this.adCommon, adGroup: params }).then((res) => {
          this.$hideLoading();
          if (res.code == 0) {
            this.$message({
              type: 'success',
              message: '保存成功',
            });
            this.close();
          }
        });
      });
    },
    // 设置广告组受众地区
    onChangeLocations(value, key) {
      console.log(this.enumConstants.countries);
      console.log(value);
      let location = this.enumConstants.countries.filter((v) =>
        value.map((key) => key.country_code).includes(v.country_code),
      );
      let languagesDefault = [...new Set(location.map((v) => v.defaultLanguageIds).flat())];
      this.$set(this.adsMsg.groupCriterion.target, 'languages', languagesDefault);
      this.$set(this.adsMsg.groupCriterion, key, value);
    },
    // 选择受众群体
    selectAudienceGroup(v) {
      console.log(v);
      if (v) {
        this.adsMsg.groupCriterion.audience = { ...this.adsMsg.groupCriterion.audience, ...v };
      } else {
        this.adsMsg.groupCriterion.audience = {
          ageRange: {
            minAge: 18,
            maxAge: null,
            undetermined: true,
          }, //年龄
          genders: ['UNDETERMINED', 'MALE', 'FEMALE'], // 性别
          languages: [], // 语言
          userInterests: [], // 兴趣词
          customAudience: [], // 自定义受众
          ...this.adsMsg.groupCriterion.audience,
        };
      }
    },
    // 设置受众定位
    setAudiencePosition(v) {
      this.adsMsg = { ...this.adsMsg, ...v };
    },
  },
};
</script>
