<template>
  <div>
    <el-drawer
      title="编辑广告"
      size="1200px"
      append-to-body
      :visible="editAdShow"
      @close="close"
      @opened="openInit"
      v-loading="adsInfoLoading"
    >
      <div class="h-full p-5 pb-20">
        <el-form
          ref="form"
          label-width="120px"
          class="h-full overflow-y-auto"
          :model="adsMsg"
          :rules="rules"
        >
          <el-form-item
            label="广告名称"
            prop="name"
          >
            <el-input
              v-model="adsMsg.name"
              placeholder="广告名称"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="广告状态"
            prop="status"
          >
            <el-switch
              v-model="adsMsg.status"
              active-text="开启"
              inactive-text="暂停"
              active-value="ENABLED"
              inactive-value="PAUSED"
            >
            </el-switch>
          </el-form-item>
          <el-form-item
            label="广告类型"
            prop=""
          >
            <el-tabs
              type="border-card"
              v-model="adsMsg.type"
              disabled
            >
              <el-tab-pane
                label="图片"
                name="DEMAND_GEN_MULTI_ASSET_AD"
              >
              </el-tab-pane>
              <el-tab-pane
                label="视频"
                name="DEMAND_GEN_VIDEO_RESPONSIVE_AD"
              >
              </el-tab-pane>
              <div>
                <!-- 图片 -->
                <el-form-item
                  class="flex"
                  prop="demandGenAd.multiImages"
                  v-show="adsMsg.type == 'DEMAND_GEN_MULTI_ASSET_AD'"
                >
                  <UploadImage
                    :value.sync="adsMsg.demandGenAd.multiImages"
                    :currentAccount="createAdAccount"
                  />
                </el-form-item>
                <!-- 视频 -->
                <el-form-item
                  class="flex"
                  prop="demandGenAd.videoAssets"
                  v-show="adsMsg.type == 'DEMAND_GEN_VIDEO_RESPONSIVE_AD'"
                >
                  <UploadVideo
                    v-model="adsMsg.demandGenAd.videoAssets"
                    :currentAccount="createAdAccount"
                    @input="(val) => getCreativeValue(val, 'videoAssets')"
                  />
                </el-form-item>
              </div>
            </el-tabs>
          </el-form-item>
          <el-form-item
            label="徽标"
            prop="demandGenAd.logoImages"
          >
            <UploadLogo
              v-model="adsMsg.demandGenAd.logoImages"
              :currentAccount="createAdAccount"
              :type="adsMsg.type"
            />
          </el-form-item>
          <el-form-item
            label="标题"
            prop="demandGenAd.headlines"
          >
            <CreativeTitle
              v-model="adsMsg.demandGenAd.headlines"
              :maxlength="40"
              type="标题"
            />
          </el-form-item>
          <el-form-item
            label="长标题"
            prop="demandGenAd.longHeadlines"
            v-show="adsMsg.type == 'DEMAND_GEN_VIDEO_RESPONSIVE_AD'"
          >
            <CreativeTitle
              type="长标题"
              v-model="adsMsg.demandGenAd.longHeadlines"
              :maxlength="90"
              @input="(val) => getCreativeValue(val, 'longHeadlines')"
            />
          </el-form-item>
          <el-form-item
            label="描述"
            prop="demandGenAd.descriptions"
          >
            <CreativeTitle
              type="描述"
              v-model="adsMsg.demandGenAd.descriptions"
              :maxlength="90"
            />
          </el-form-item>
          <el-form-item
            label="行动号召"
            prop="demandGenAd.callToActions"
            v-show="adsMsg.type == 'DEMAND_GEN_VIDEO_RESPONSIVE_AD'"
          >
            <CallToAction
              type="video"
              v-model="adsMsg.demandGenAd.callToActions"
              :enumConstants="enumConstants"
              @input="(val) => getCreativeValue(val, 'callToActions')"
            />
          </el-form-item>
          <el-form-item
            label="行动号召"
            prop="demandGenAd.callToAction"
            v-show="adsMsg.type != 'DEMAND_GEN_VIDEO_RESPONSIVE_AD'"
          >
            <CallToAction
              type="img"
              v-model="adsMsg.demandGenAd.callToAction"
              :enumConstants="enumConstants"
              @input="(val) => getCreativeValue(val, 'callToAction')"
            />
          </el-form-item>
          <el-form-item
            label="商家名称"
            prop="demandGenAd.businessName"
          >
            <el-input
              v-model="adsMsg.demandGenAd.businessName"
              class="flex-1"
            ></el-input>
          </el-form-item>
          <el-form></el-form>
        </el-form>
      </div>
      <div class="absolute right-3 bottom-5">
        <el-button @click="close">取 消</el-button>
        <el-button
          type="primary"
          @click="sure"
          >确 定</el-button
        >
      </div>
    </el-drawer>
  </div>
</template>
<script>
import CreativeTitle from '@/views/google/create/components/creatives/CreativeTitle/index.vue';
import CallToAction from '@/views/google/create/components/creatives/CallToAction/index.vue';
import UploadImage from '@/views/google/create/components/creatives/UploadImage/index.vue';
import UploadLogo from '@/views/google/create/components/creatives/UploadLogo/index.vue';
import UploadVideo from '@/views/google/create/components/creatives/UploadVideo/index.vue';
import { callToActionList, callToActionMap } from '@/views/google/create/index';
import { adDetail, updateAd } from '@/api/google/create';
import { mapState } from 'vuex';
import {
  validateWord,
  validatorVideo,
  validatorMedia,
  validatorCollMedia,
} from '@/views/google/create/components/creatives/index';
// 图片视频通用校验
const baseRules = {
  name: [
    {
      required: true,
      message: '广告名称必填',
    },
  ],
  status: [{ required: true }],
  'demandGenAd.logoImages': [
    {
      required: true,
      validator: validatorCollMedia,
    },
  ],
  'demandGenAd.headlines': [
    {
      required: true,
      validator: (rule, value, callback) => validateWord('标题', 40, rule, value, callback),
    },
  ],
  'demandGenAd.descriptions': [
    {
      required: true,
      validator: (rule, value, callback) => validateWord('描述', 90, rule, value, callback),
    },
  ],
  'demandGenAd.businessName': [
    {
      required: true,
      message: '商家名称必填',
    },
  ],
};
// 图片视频校验
export const imgRules = {
  ...baseRules,
  'demandGenAd.multiImages': [
    {
      validator: validatorMedia,
      required: true,
    },
  ],
};
// 图片视频校验
export const videoRules = {
  ...baseRules,
  'demandGenAd.longHeadlines': [
    {
      required: true,
      validator: (rule, value, callback) => validateWord('视频长标题', 90, rule, value, callback),
    },
  ],
  'demandGenAd.videoAssets': [
    {
      validator: validatorVideo,
      required: true,
      trigger: 'change',
    },
  ],
};
export default {
  components: {
    CreativeTitle,
    CallToAction,
    UploadImage,
    UploadLogo,
    UploadVideo,
  },
  props: {
    editAdShow: {
      type: Boolean,
      default: false,
    },
    currentRow: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapState('google', ['createAdAccount']),
    rules() {
      if (this.adsMsg.type == 'DEMAND_GEN_VIDEO_RESPONSIVE_AD') {
        return this.videoRules;
      } else {
        return this.imgRules;
      }
    },
  },
  data() {
    return {
      callToActionMap,
      adsMsg: {
        demandGenAd: {},
      },
      enumConstants: {
        callToActionList,
      },
      adsInfoLoading: false,
      adCommon: {},
      imgRules,
      videoRules,
    };
  },
  methods: {
    // 初始化弹窗
    openInit() {
      this.getAdsInfo();
    },
    // 获取广告信息
    getAdsInfo() {
      let params = {
        level: 'ad',
        accountId: this.createAdAccount.id,
        accountName: this.createAdAccount.name,
        campaignId: this.currentRow.campaign_id,
        adGroupId: this.currentRow.adset_id,
        adId: this.currentRow.id,
      };
      this.adsInfoLoading = true;
      adDetail(params)
        .then((res) => {
          if (res.code == 0) {
            this.adsMsg = res.data.ad;
            this.adCommon = { ...res.data };
            this.adsMsg.demandGenAd.callToActions =
              res.data.ad.demandGenAd.callToActions && res.data.ad.demandGenAd.callToActions.length
                ? res.data.ad.demandGenAd.callToActions.map((v) => {
                    console.log(callToActionList);
                    let action = callToActionList.find((action) => action.value == v.key);
                    console.log(action);
                    // for (let key in callToActionMap) {
                    //   if (callToActionMap[key] == v.key) {
                    //     v.action = key;
                    //   }
                    // }
                    v.action = action.key;
                    return v;
                  })
                : [];
            this.adsMsg.demandGenAd.videoAssets = this.adsMsg.demandGenAd.videoAssets.map((v) => {
              return { ...v, status: 'succeeded', process: 100, type: 'youtube' };
            });
          }
          console.log(this.adsMsg);
        })
        .finally(() => {
          this.adsInfoLoading = false;
        });
    },
    //关闭弹窗
    close() {
      this.$emit('update:editAdShow', false);
    },
    //点击确定
    sure() {
      this.$refs.form.validate((validate) => {
        console.log(validate);
        if (!validate) {
          return false;
        }
        this.$showLoading();
        updateAd({ ...this.adCommon, ad: this.adsMsg }).then((res) => {
          this.$hideLoading();
          if (res.code == 0) {
            this.$message({
              type: 'success',
              message: '保存成功',
            });
            this.close();
          }
        });
      });
    },
    // 设置广告内容
    getCreativeValue(value, key) {
      this.$set(this.adsMsg.demandGenAd, key, value);
    },
  },
};
</script>
